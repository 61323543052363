.app-container {
  margin-top: 135px; }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-track {
  background: #888; }

::-webkit-scrollbar-thumb {
  background: #77B955; }

::-webkit-scrollbar-thumb:hover {
  background-color: #6ea552; }

.go-to-new {
  position: absolute;
  bottom: 0;
  background-color: red;
  cursor: pointer;
  padding: 10px;
  font-weight: 700;
  position: sticky;
  text-align: center; }
