.report-header {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  align-items: center; }
  .report-header h1 {
    text-align: center; }
  .report-header button {
    margin-left: 20px;
    height: 40px; }
  .report-header .filter {
    display: flex;
    margin-left: 5px; }
    .report-header .filter i {
      cursor: pointer; }
