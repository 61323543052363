table.report thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 100px;
  z-index: 10; }

table.report th {
  cursor: pointer; }

table.report td, table.report th {
  text-align: center; }

table.report td:first-child, table.report th:first-child {
  text-align: left;
  box-shadow: inset -5px 0px 0px 0px lightgray;
  background-color: white;
  color: black; }

table.report tbody tr:hover {
  background-color: #BDC3C7;
  color: #FFFFFF; }
