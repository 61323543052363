header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%; }

.container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 10px; }

.btn-reports {
  margin: 0 10px;
  max-height: 65px;
  overflow: auto;
  min-width: 180px; }

.btn-tool {
  border: unset;
  font-size: 14px;
  cursor: pointer;
  margin: 2px;
  background-color: #77B955;
  outline: none;
  transition: all .3s; }

.btn-tool:hover {
  background-color: #487c2e; }

.btn-tool:focus {
  outline: none; }

.active {
  background-color: #deffcb;
  font-weight: 800; }

.report-params-panel {
  padding: 10px;
  text-align: center;
  margin-bottom: 10px; }
  .report-params-panel label {
    margin-right: 10px; }

.report-hint {
  margin: 10px;
  text-align: center; }

.select {
  width: 400px;
  text-align: start; }

.details-tools {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  align-items: center; }
  .details-tools span {
    color: white;
    margin-right: 5px; }
