header {
  background-color: #404b55; }
  header .fa {
    margin-right: 10px; }
  header .navbar-brand {
    font-size: 24px; }
  header .navbar {
    margin: 0; }
  header .linksList {
    flex-direction: row; }
  header .link {
    color: white;
    font-size: 20px;
    padding-left: 30px; }
    header .link:hover {
      color: yellowgreen; }
  header .linkOn {
    background-color: #4cae4c; }
    header .linkOn:hover {
      color: black;
      background-color: #4cae4c; }
    header .linkOn:focus {
      color: white;
      background-color: #4cae4c !important; }
